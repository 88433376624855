<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{blogData?.pageTitle}}</h2>
            <ul>
                <li><a routerLink="/">{{blogData?.breadcrumb.home}}</a></li>
                <li class="active">{{blogData?.breadcrumb.current}}</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img [src]="blogData?.article.image" alt="image">
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><span>Publié le :</span> <a routerLink="/blog">{{blogData?.article.meta.date}}</a></li>
                                <li><span>Publié par :</span> <a routerLink="/blog">{{blogData?.article.meta.author}}</a></li>
                            </ul>
                        </div>
                        <h3>{{blogData?.article.title}}</h3>
                        <ng-container *ngFor="let content of blogData?.article.content">
                            <blockquote *ngIf="content.type === 'quote'" class="flaticon-quote">
                                <p>{{content.text}}</p>
                            </blockquote>
                            <p *ngIf="content.type === 'paragraph'">{{content.text}}</p>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <div class="widget widget_search">
                        <h3 class="widget-title">Chercher un article</h3>

                        <div class="post-wrap">
                            <form class="search-form">
                                <label>
                                    <span class="screen-reader-text">Rechercher:</span>
                                    <input
                                        type="search"
                                        class="search-field"
                                        [(ngModel)]="searchQuery"
                                        (input)="searchBlogs()"
                                        name="search"
                                        placeholder="Rechercher un article...">
                                </label>
                            </form>
                        </div>
                    </div>

                    <!-- Show search results if available -->
                    <app-search-results
                        *ngIf="showSearchResults"
                        [searchResults]="searchResults"
                        [searchQuery]="searchQuery">
                    </app-search-results>

                    <!-- Show similar articles if no search is active -->
                    <app-similar-articles
                        [articleIds]="blogData?.similarArticles">
                    </app-similar-articles>
                </aside>
            </div>
        </div>
    </div>
</section>
