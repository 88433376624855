<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Développement</h2>
            <h2 style="color: white;">Solutions technologiques innovantes</h2>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>


<section id="conception" class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <h2>Conception et stratégie digitale</h2>
                        <p>Nous concevons et développons des solutions technologiques performantes et innovantes, adaptées à vos besoins métiers. De la conception à la maintenance, nous vous accompagnons à chaque étape pour garantir la réussite de vos projets numériques.</p>
                        <ul>
                            <li><i class='flaticon-correct'></i> Analyse de vos besoins et définition des objectifs</li>
                            <li><i class='flaticon-correct'></i> Création de maquettes et prototypage</li>
                            <li><i class='flaticon-correct'></i> Élaboration d'une feuille de route technologique</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section id="development" class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img1.jpg" alt="choose" />

                    <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play'></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Développement sur mesure</h2>
                    <p>Nous développons des solutions adaptées à vos besoins spécifiques, en utilisant les technologies les plus performantes et innovantes.</p>
                    <ul>
                        <li><i class='flaticon-correct'></i> Développement d'applications web et mobiles innovantes</li>
                        <li><i class='flaticon-correct'></i> Création de plateformes personnalisées</li>
                        <li><i class='flaticon-correct'></i> Intégration avec vos systèmes existants (ERP, CRM, etc.)</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="maintenance" class="feature-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>Maintenance et évolutions</h2>
                        <p>Nous assurons le suivi et l'optimisation continue de vos solutions numériques pour garantir leur performance et leur sécurité.</p>
                        <ul>
                            <li><i class="flaticon-correct"></i> Suivi et optimisation continue</li>
                            <li><i class="flaticon-correct"></i> Maintenance corrective et évolutive</li>
                            <li><i class="flaticon-correct"></i> Veille technologique</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<section id="modernisation" class="technology-area ptb-100">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img1.jpg" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Modernisation des systèmes</h2>
                    <p>Nous vous accompagnons dans la transformation de vos systèmes existants pour répondre aux exigences actuelles du marché.</p>
                    <ul>
                        <li><i class='flaticon-correct'></i> Migration de systèmes anciens vers des technologies modernes</li>
                        <li><i class='flaticon-correct'></i> Optimisation des performances pour répondre aux exigences actuelles</li>
                        <li><i class='flaticon-correct'></i> Amélioration de l'expérience utilisateur pour vos clients et équipes</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="collaboration" class="feature-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>Collaboration Agile et transparente</h2>
                        <p>Notre approche collaborative garantit une communication fluide et un suivi efficace de vos projets.</p>
                        <ul>
                            <li><i class="flaticon-correct"></i> Approche collaborative basée sur des méthodologies Agile (Scrum, Kanban)</li>
                            <li><i class="flaticon-correct"></i> Suivi régulier des avancées grâce à des outils modernes (Jira, Trello)</li>
                            <li><i class="flaticon-correct"></i> Communication fluide et adaptée pour un pilotage efficace du projet</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feature-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <h2>Pourquoi nous choisir ?</h2>
            <p>Notre approche unique combine expertise technique, solutions personnalisées et accompagnement complet.</p>
        </div>
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 justify-content-center g-4">
            <div class="col-lg-3 col-md-6">
                <div class="single-feature text-center">
                    <i class='bx bx-code-block' style="font-size: 48px; color: var(--mainColor);"></i>
                    <h3>Expertise technologique</h3>
                    <p>Une équipe de développeurs expérimentés et passionnés.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-feature text-center">
                    <i class='bx bx-customize' style="font-size: 48px; color: var(--mainColor);"></i>
                    <h3>Solutions sur mesure</h3>
                    <p>Des projets adaptés à vos besoins spécifiques et à vos contraintes.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-feature text-center">
                    <i class='bx bx-support' style="font-size: 48px; color: var(--mainColor);"></i>
                    <h3>Accompagnement complet</h3>
                    <p>Présence à vos côtés à chaque étape, de l'idée initiale à la mise en œuvre.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-feature text-center" (click)="scrollToSection('modernisation')">
                    <i class='bx bx-sync' style="font-size: 48px; color: var(--mainColor);"></i>
                    <h3>Modernisation</h3>
                    <p>Transformation des systèmes existants</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-feature text-center" (click)="scrollToSection('collaboration')">
                    <i class='bx bx-git-branch' style="font-size: 48px; color: var(--mainColor);"></i>
                    <h3>Collaboration Agile</h3>
                    <p>Méthodologies et outils modernes</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 text-center">
                <h2>Prêt à démarrer votre projet ?</h2>
                <p class="mt-4">Prenez contact avec notre équipe pour discuter de vos idées et découvrir comment transformer vos besoins en solutions technologiques innovantes.</p>
                <a (click)="navigateToContact()" class="box-btn mt-4" style="cursor: pointer; width: fit-content;">Contactez-nous</a>
            </div>
        </div>
    </div>
</section>

<app-sticky-contact></app-sticky-contact>

<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe
            width="853"
            height="480"
            src="https://www.youtube.com/embed/VCJW7GZEF50"
            title="Les services de Beyond Expertise"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen>
        </iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='bx bx-x'></i>
        </button>
    </div>
</div>
