<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{formationData?.title}}</h2>
            <div class="formation-highlights">
                <div class="highlight-item">
                    <i class="bx bx-time"></i>
                    <span>{{formationData?.duree?.jours}} jours ({{formationData?.duree?.heures}} heures)</span>
                </div>
                <div class="highlight-item">
                    <i class="bx bx-certification"></i>
                    <span>{{formationData?.format}}</span>
                </div>
                <div class="highlight-item">
                    <i class="bx bx-euro"></i>
                    <span>{{formationData?.practical?.price}}</span>
                </div>
                <div class="highlight-item">
                    <i class="bx bx-map-pin"></i>
                    <span>{{formationData?.practical?.location}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<div style="background-color: #f5faff;" class="formation-content">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="content-wrapper">
                    <div class="formation-nav sticky-top">
                        <nav class="formation-tabs">
                            <ul>
                                <li [class.active]="activeSection === 'section-target'">
                                    <a (click)="scrollToSection('section-target')" [class.active]="activeSection === 'section-target'">
                                       <h3>Public visé</h3>
                                    </a>
                                </li>
                                <li [class.active]="activeSection === 'section-prerequisites'">
                                    <a (click)="scrollToSection('section-prerequisites')" [class.active]="activeSection === 'section-prerequisites'">
                                        <h3>Prérequis</h3>
                                    </a>
                                </li>
                                <li [class.active]="activeSection === 'section-objectives'">
                                    <a (click)="scrollToSection('section-objectives')" [class.active]="activeSection === 'section-objectives'">
                                        <h3>Objectifs</h3>
                                    </a>
                                </li>
                                <li [class.active]="activeSection === 'section-program'">
                                    <a (click)="scrollToSection('section-program')" [class.active]="activeSection === 'section-program'">
                                        <h3>Programme</h3>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div class="formation-sections">
                        <section id="section-target"
                            class="content-section"
                            [class.active]="activeSection === 'section-target'"
                            [@sectionAnimation]="activeSection === 'section-target' ? 'in' : 'void'">
                            <ul>
                                <li *ngFor="let item of formationData?.target?.audience">
                                    <i class='bx bxs-check-circle'></i>
                                    <span>{{item}}</span>
                                </li>
                            </ul>
                        </section>

                        <section id="section-prerequisites"
                            class="content-section"
                            [class.active]="activeSection === 'section-prerequisites'"
                            [@sectionAnimation]="activeSection === 'section-prerequisites' ? 'in' : 'void'">
                            <ul>
                                <li *ngFor="let item of formationData?.prerequisites?.requirements">
                                    <i class='bx bxs-check-circle'></i>
                                    <span>{{item}}</span>
                                </li>
                            </ul>
                        </section>

                        <section id="section-objectives"
                            class="content-section"
                            [class.active]="activeSection === 'section-objectives'"
                            [@sectionAnimation]="activeSection === 'section-objectives' ? 'in' : 'void'">
                            <ul>
                                <li *ngFor="let item of formationData?.objectives?.goals">
                                    <i class='bx bxs-check-circle'></i>
                                    <span>{{item}}</span>
                                </li>
                            </ul>
                        </section>

                        <section id="section-program"
                            class="content-section"
                            [class.active]="activeSection === 'section-program'"
                            [@sectionAnimation]="activeSection === 'section-program' ? 'in' : 'void'">
                            <div class="program-modules">
                                <div class="module" *ngFor="let module of formationData?.program?.modules; let i = index">
                                    <div class="module-header" (click)="toggleModule(i)">
                                        <h3>{{module.title}}</h3>
                                        <i class="bx" [class.bx-chevron-down]="!module.isOpen" [class.bx-chevron-up]="module.isOpen"></i>
                                    </div>
                                    <div class="module-content" [class.open]="module.isOpen">
                                        <ul>
                                            <li *ngFor="let topic of module.topics">
                                                <i class='bx bxs-check-circle'></i>
                                                <span>{{topic}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="formation-card sticky-top">
                    <div class="card-image">
                        <img [src]="formationData?.image" [alt]="formationData?.title" />
                    </div>
                    <div class="card-header">
                        <h3>{{formationData?.title}}</h3>
                        <hr style="border: 1px solid; margin: 0;color: darkblue;">
                    </div>
                    <div class="card-body">
                        <div class="description">
                            <p>{{formationData?.description}}</p>
                        </div>
                        <div class="details">
                            <div class="detail-item">
                                <i class="bx bx-time"></i>
                                <strong style="color: var(--darkBlueColor);">{{formationData?.duree?.heures}} heures / {{formationData?.duree?.mois}} mois</strong>
                            </div>
                            <div class="detail-item">
                                <i class="bx bx-map"></i>
                                <strong style="color: var(--darkBlueColor);">{{formationData?.practical?.location}}</strong>
                            </div>
                            <div class="detail-item">
                                <i class="bx bx-euro"></i>
                                <strong style="color: var(--darkBlueColor);">{{formationData?.practical?.price}}</strong>
                            </div>
                            <div class="detail-item">
                                <i class="bx bx-calendar"></i>
                                <strong style="color: var(--darkBlueColor);">Prochaine rentrée : {{formationData?.prochaine_rentree}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <a (click)="openContactModal()" class="box-btn">Candidater</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-sticky-contact></app-sticky-contact>

<app-contact-modal
  *ngIf="showContactModal"
  [formationTitle]="formationData?.title"
  (closeModal)="closeContactModal()"
></app-contact-modal>
