<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Consulting</h2>
            <h2 style="color: white;" >Accompagnement et conseil en transformation numérique</h2>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="feature-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="contnet">
                    <div class="row mt-5">
                        <div class="col-lg-4">
                            <div class="single-feature text-center" (click)="scrollToSection('experts')">
                                <i class='bx bx-analyse' style="font-size: 48px; color: var(--mainColor);"></i>
                                <h3>Positionnement de consultants</h3>
                                <p>Vos projets, nos experts.</p>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="single-feature text-center" (click)="scrollToSection('accompagnement')">
                                <i class='bx bx-refresh' style="font-size: 48px; color: var(--mainColor);"></i>
                                <h3>Accompagnement</h3>
                                <p>Un accompagnement pour des résultats concrets.</p>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="single-feature text-center" (click)="scrollToSection('recrutement')">
                                <i class='bx bx-group' style="font-size: 48px; color: var(--mainColor);"></i>
                                <h3>Recrutement sur mesure.</h3>
                                <p>Trouvez les talents qui transformeront votre entreprise.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="experts" class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <h2>Vos projets, nos experts</h2>
                        <p>Nous mettons à votre disposition des consultants expérimentés : Scrum Masters, Product Owners et Coachs Agile, pour gérer vos projets de transformation et optimiser vos processus. Nos experts s'intègrent parfaitement à vos équipes, garantissant une collaboration fluide et des résultats mesurables .</p>
                        <ul>
                            <li><i class='flaticon-correct'></i> Gestion de projets de transformation digitale</li>
                            <li><i class='flaticon-correct'></i> Optimisation des processus Agile</li>
                            <li><i class='flaticon-correct'></i> Accompagnement des équipes</li>
                            <li><i class='flaticon-correct'></i> Formation et coaching</li>
                            <li><i class='flaticon-correct'></i> Support technique et stratégique</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>


<section id="accompagnement" class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img1.jpg" alt="choose" />

                    <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Un accompagnement pour des résultats concrets.</h2>
                    <p>Nos experts vous fournissent à identifier vos besoins, définir vos priorités, et mettre en œuvre des solutions sur mesure pour accélérer vos transformations organisationnelles.</p>
                    <ul>
                        <li><i class='flaticon-correct'></i> Diagnostic des besoins et audit organisationnel.</li>
                        <li><i class='flaticon-correct'></i> Formation et montée en compétence des équipes internes.</li>
                        <li><i class='flaticon-correct'></i> Suivi et évaluation des performances post-projet.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe width="853" height="480" src="https://www.youtube.com/embed/VCJW7GZEF50" title="Les services de Beyond Expertise" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='bx bx-x'></i>
        </button>
    </div>
</div>

<section id="recrutement" class="feature-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>Trouvez les talents qui transformeront votre entreprise.</h2>
                        <p>Nous accompagnons nos clients dans la recherche et la sélection des meilleurs profils. Que ce soit pour des missions courtes ou des recrutements long terme, nous identifions les talents parfaitement alignés avec vos besoins.</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> Analyse des besoins de recrutement.</li>
                        <li><i class="flaticon-correct"></i> Recherche, présélection et entretien des candidats.                        </li>
                        <li><i class="flaticon-correct"></i> Intégration réussie grâce à un suivi personnalisé.</li>
                    </ul>
                    <a routerLink="/" fragment="contact" class="box-btn">Contacter nous</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<app-sticky-contact></app-sticky-contact>
