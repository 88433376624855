<div class="main-banner-area">
    <section class="banner-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="banner-content">
                                <h1>Au cœur de votre transformation professionnelle</h1>
                                <p>Chez Beyond Expertise, nous façonnons les leaders de demain grâce à des formations innovantes et adaptées à vos besoins.
                                    <strong>Your satisfaction is our starting point</strong>.
                                </p>
                                    <p>Que vous soyez une entreprise ou un professionnel, nous vous accompagnons à chaque étape pour atteindre vos objectifs de transformation, d'innovation et de montée en compétences.</p>
                                <div class="banner-btn">
                                    <a routerLink="/formations-list" class="box-btn border-btn">JE TROUVE MA FORMATION</a>
                                    <a routerLink="/consulting" class="box-btn border-btn">JE DEMANDE UNE CONSULTATION</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="banner-img">
                                <img src="assets/images/home-bg-1-img.png" alt="banner-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="home-shape">
            <div class="shape1"><img src="assets/images/shape/1.png" alt="shape"></div>
            <div class="shape2"><img src="assets/images/shape/2.png" alt="shape"></div>
            <div class="shape3"><img src="assets/images/shape/3.png" alt="shape"></div>
            <div class="shape4"><img src="assets/images/shape/4.png" alt="shape"></div>
            <div class="shape5"><img src="assets/images/shape/5.png" alt="shape"></div>
            <div class="shape6"><img src="assets/images/shape/6.png" alt="shape"></div>
        </div>
    </section>
</div>

<section class="home-service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Beyond Expertise</h2>
            <h3>Découvrez Nos Solutions de Transformation</h3>
            <p>Nous vous accompagnons dans la réussite de vos projets stratégiques et innovants grâce à nos services d’excellence. Explorez nos domaines d’expertise pour propulser votre entreprise.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Consulting</h3>
                        <p>Nous offrons un accompagnement stratégique pour optimiser vos projets et garantir leur succès. Bénéficiez de solutions adaptées à vos besoins pour relever vos défis professionnels.</p>
                        <div class="text-center">
                            <a class="box-btn mt-4" routerLink="/consulting" style="width: fit-content; display: inline-block; padding: 8px 20px; border-radius: 20px; font-size: 14px;">EN SAVOIR PLUS</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Formation</h3>
                        <p>Nous proposons des formations sur mesure pour développer les compétences de vos équipes. Bénéficiez d'un accompagnement pédagogique et d'un contenu adapté à vos enjeux professionnels.</p>
                        <div class="text-center">
                            <a class="box-btn mt-4" routerLink="/formations-list" style="width: fit-content; display: inline-block; padding: 8px 20px; border-radius: 20px; font-size: 14px;">EN SAVOIR PLUS</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Dévelopment</h3>
                        <p>Nous concevons et développons des solutions technologiques performantes et innovantes. De la conception à la maintenance, nous sommes votre partenaire pour vos projets numériques.</p>
                        <div class="text-center">
                            <a class="box-btn mt-4" routerLink="/development" style="width: fit-content; display: inline-block; padding: 8px 20px; border-radius: 20px; font-size: 14px;">EN SAVOIR PLUS</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>



<section style="background-color: #f5faff;" class="choose-area ptb-100">
    <div class="container" style="max-width: 1000px;">
        <div class="section-title">
            <h2>Nos formations</h2>
            <p>Nous avons gagné la confiance de nos clients grâce à la qualité de nos services et de nos formations professionnelles.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img.png" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <div class="faq-item" *ngFor="let item of accordionItems; let i = index;">
                            <div class="faq-header" (click)="toggleAccordionItem(item)">
                                {{item.title}}
                                <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                            </div>
                            <div class="faq-content" [hidden]="!item.open">
                                <p>{{item.content}}</p>
                                <div class="text-center mt-3">
                                    <a class="box-btn"
                                       [routerLink]="['/formations-list']"
                                       [queryParams]="{type: item.id}"
                                       style="width: fit-content; display: inline-block; padding: 8px 20px; border-radius: 20px; font-size: 14px;">
                                        EN SAVOIR PLUS
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>




<section style="background-color: #ffffff;" class="home-company-area bg-color pt-70 pb-70" id="about">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <h2>Une expertise innovante au service de votre croissance</h2>
                        <p>Depuis des années,<strong> Beyond Expertise </strong> accompagne les entreprises et les particuliers dans leur développement à travers des solutions de formation sur mesure et une expertise de pointe. Que vous soyez une TPE/PME, un grand groupe ou à titre personnel, nous vous offrons des outils pratiques et concrets pour relever vos défis d'aujourd'hui et anticiper ceux de demain.</p>
                        <p>Grâce à notre approche personnalisée, nous plaçons votre satisfaction au cœur de nos priorités, car "Your satisfaction is our starting point".</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>


<section class="home-process-area pt-70 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{processData?.subtitle}}</h2>
            <p>{{processData?.description}}</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6" *ngFor="let step of processData?.steps">
                <div class="single-process">
                    <div class="icon">
                        <img [src]="step.icon" alt="process" />
                        <span *ngIf="step.hasNext"><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>{{step.title}}</h3>
                        <p>{{step.content}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feature-area bg-color pt-70 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <span>{{featuresData?.title}}</span>
                        <h2>{{featuresData?.subtitle}}</h2>
                        <p>{{featuresData?.description}}</p>
                    </div>
                    <ul>
                        <li *ngFor="let feature of featuresData?.features">
                            <i [class]="feature.icon"></i>
                            <strong>{{feature.title}}</strong>
                            <p>{{feature.description}}</p>
                        </li>
                    </ul>
                    <div class="text-center mt-4">
                        <p>
                            {{featuresData?.ctaText}}
                            <a [href]="featuresData?.ctaLink">{{featuresData?.ctaLinkText}}</a>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-team-area pt-70 pb-70">
    <div class="container">
        <div class="section-title">
            <span>{{teamData?.title}}</span>
            <h2>{{teamData?.subtitle}}</h2>
            <p>{{teamData?.description}}</p>
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide *ngFor="let member of teamData?.members">
                    <div class="single-team">
                        <div class="team-img">
                            <img [src]="member.image" [alt]="member.name" />

                            <ul class="social">
                                <li *ngIf="member.social.linkedin">
                                    <a [href]="member.social.linkedin" target="_blank">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                                <li *ngIf="member.social.facebook">
                                    <a [href]="member.social.facebook" target="_blank">
                                        <i class="bx bxl-facebook"></i>
                                    </a>
                                </li>
                                <li *ngIf="member.social.twitter">
                                    <a [href]="member.social.twitter" target="_blank">
                                        <i class="bx bxl-twitter"></i>
                                    </a>
                                </li>
                                <li *ngIf="member.social.email">
                                    <a [href]="member.social.email" target="_blank">
                                        <i class="bx bxs-envelope"></i>
                                    </a>
                                </li>
                            </ul>

                        </div>

                        <div class="content text-center">
                            <h3>{{member.name}}</h3>
                            <p>{{member.role}}</p>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>

<section class="client-area pt-70 pb-70 bg-color">
    <div class="container">
        <div class="section-title">
            <span>{{clientsData?.title}}</span>
            <h2>{{clientsData?.subtitle}}</h2>
            <p>{{clientsData?.description}}</p>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientSlides">
                <ng-template carouselSlide *ngFor="let client of clientsData?.clients">
                    <div class="single-client">
                        <a [href]="client.link" [title]="client.description">
                            <img [src]="client.logo" [alt]="client.name" />
                        </a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>

<section style="background-color: #ffffff;" class="home-contact-area bg-color pt-70 pb-70" id="contact">
    <div class="container">
        <div class="section-title">
            <span>{{contactData?.title}}</span>
            <h2>{{contactData?.subtitle}}</h2>
            <p>{{contactData?.description}}</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="contact-img">
                    <img src="assets/images/contact-img.png" alt="contact">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <h3 class="form-title">{{contactData?.formTitle}}</h3>
                    <form id="contactForm" (ngSubmit)="onSubmit($event)">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        class="form-control"
                                        required
                                        [(ngModel)]="formData.name"
                                        [placeholder]="contactData?.form.name.placeholder"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        class="form-control"
                                        required
                                        [(ngModel)]="formData.email"
                                        [placeholder]="contactData?.form.email.placeholder"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        name="phone_number"
                                        id="phone_number"
                                        required
                                        class="form-control"
                                        [(ngModel)]="formData.phone_number"
                                        [placeholder]="contactData?.form.phone.placeholder"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        name="msg_subject"
                                        id="msg_subject"
                                        class="form-control"
                                        required
                                        [(ngModel)]="formData.msg_subject"
                                        [placeholder]="contactData?.form.subject.placeholder"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea
                                        name="message"
                                        class="form-control"
                                        id="message"
                                        cols="30"
                                        rows="5"
                                        required
                                        [(ngModel)]="formData.message"
                                        [placeholder]="contactData?.form.message.placeholder"
                                    ></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button
                                    type="submit"
                                    class="default-btn page-btn box-btn"
                                    [disabled]="isSubmitting"
                                >
                                    {{isSubmitting ? 'Envoi en cours...' : contactData?.form.submitButton}}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<app-sticky-contact></app-sticky-contact>
