<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Nos Formations</h2>
            <h2>{{servicesData?.subtitle}}</h2>
            <h3 style="color: white;">{{servicesData?.description}}</h3>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<div style="height: 40px; background-color: #f5faff;"></div>

<section style="background-color: #f5faff;" class="formations-list pt-40 pb-40">
    <div class="container">
        <div class="search-wrapper">
            <div class="search-box">
                <i class="bx bx-search"></i>
                <input
                    type="text"
                    [(ngModel)]="searchTerm"
                    (input)="onSearch()"
                    placeholder="Rechercher une formation..."
                    class="search-input"
                >
            </div>
        </div>

        <div class="section-title" *ngIf="!isSearching">
            <div class="nav-tabset-wrapper">
                <ul class="nav-tabset" [class.mobile-tabs]="isMobile">
                    <li class="nav-tab" *ngFor="let tab of servicesData?.tabs"
                        [ngClass]="{'active': currentTab === tab.id}">
                        <h3 (click)="switchTab($event, tab.id)">
                            {{tab.label}}
                        </h3>
                    </li>
                </ul>
            </div>
        </div>

        <div class="section-title" *ngIf="isSearching">
            <h2>Résultats de recherche</h2>
            <p *ngIf="filteredFormations.length > 0">
                {{filteredFormations.length}} formation(s) trouvée(s) pour "{{searchTerm}}"
            </p>
        </div>

        <div class="case">
            <div class="tabs-container">
                <div class="loading" *ngIf="isLoading && !currentTab">
                    <p>Chargement des formations...</p>
                </div>
                <div class="pane" [id]="currentTab" *ngIf="filteredFormations.length > 0">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 d-flex" *ngFor="let formation of filteredFormations">
                            <div class="formation-card">
                                <div class="card-image">
                                    <img src="{{formation.image}}" alt="{{formation.title}}" />
                                </div>
                                <div class="card-header">
                                    <h3>{{formation.title}}</h3>
                                    <hr style="border: 1px solid; margin: 0;color: darkblue;">
                                </div>
                                <div class="card-body">
                                    <div class="description">
                                        <p>{{formation.description}}</p>
                                    </div>
                                    <div class="details">
                                        <div class="detail-item">
                                            <i class="bx bx-time"></i>
                                            <strong style="color: var(--darkBlueColor);" >{{formation.duree.heures}} heures / {{formation.duree.mois}} mois</strong>
                                        </div>
                                        <div class="detail-item">
                                            <i class="bx bx-map"></i>
                                            <strong style="color: var(--darkBlueColor);">{{formation.modalite}} - {{formation.localisation}}</strong>
                                        </div>
                                        <div class="detail-item">
                                            <i class="bx bx-calendar"></i>
                                            <strong style="color: var(--darkBlueColor);">Prochaine rentrée : {{formation.prochaine_rentree}}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <a [routerLink]="['/formation', formation.id]" class="box-btn">En savoir plus</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="no-formations" *ngIf="!isLoading && filteredFormations.length === 0">
                    <p *ngIf="isSearching">Aucune formation trouvée pour "{{searchTerm}}"</p>
                    <p *ngIf="!isSearching">Aucune formation disponible pour cette catégorie.</p>
                </div>
            </div>
        </div>
        <div class="case-btn text-center">
            <p>{{servicesData?.viewMoreText}} <a [routerLink]="servicesData?.viewMoreLink"></a></p>
            <p class="mt-4">
                {{servicesData?.contactText}}
                <a [href]="servicesData?.contactLink">{{servicesData?.contactLinkText}}</a>
                {{servicesData?.contactSuffix}}
            </p>
        </div>
    </div>
</section>

<app-sticky-contact></app-sticky-contact>
