<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Notre blog</h2>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Derniers Articles</h2>
            <p>Découvrez nos dernières actualités, conseils et analyses sur les tendances du secteur.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let blog of blogs">
                <div class="formation-card">
                    <div class="card-img">
                        <a [routerLink]="['/blog-details', blog.id]">
                            <img [src]="blog.image" [alt]="blog.title">
                        </a>
                    </div>
                    <div class="card-content">
                        <div class="meta-info">
                            <span class="date">
                                <i class="bx bx-calendar"></i>
                                {{blog.meta.date}}
                            </span>
                            <span class="author">
                                <i class="bx bx-user"></i>
                                {{blog.meta.author}}
                            </span>
                        </div>
                        <h3 class="card-title">
                            <a [routerLink]="['/blog-details', blog.id]">{{blog.title}}</a>
                        </h3>
                        <p class="card-description">{{blog.excerpt}}</p>
                        <a [routerLink]="['/blog-details', blog.id]" class="read-more">
                            Lire la suite <i class="bx bx-right-arrow-alt"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-sticky-contact></app-sticky-contact>
