<div class="modal-overlay">
  <div class="modal-content">
    <div class="modal-header">
      <h3>Inscription à la formation</h3>
      <button class="close-btn" (click)="close()">
        <i class='bx bx-x'></i>
      </button>
    </div>

    <div class="modal-body">
      <h4>{{formationTitle}}</h4>

      <!-- Success Message -->
      <div class="alert alert-success" *ngIf="showSuccessMessage">
        Votre demande a été envoyée avec succès ! Nous vous contacterons bientôt.
      </div>

      <!-- Error Message -->
      <div class="alert alert-danger" *ngIf="showErrorMessage">
        Une erreur s'est produite. Veuillez réessayer plus tard.
      </div>

      <form id="contactForm" (ngSubmit)="onSubmit($event)">
        <div class="row">
          <div class="col-lg-12 col-sm-12">
            <div class="form-group">
              <input
                type="text"
                name="name"
                id="name"
                class="form-control"
                required
                [(ngModel)]="formData.name"
                placeholder="Votre nom"
              />
            </div>
          </div>

          <div class="col-lg-12 col-sm-12">
            <div class="form-group">
              <input
                type="email"
                name="email"
                id="email"
                class="form-control"
                required
                [(ngModel)]="formData.email"
                placeholder="Votre email"
              />
            </div>
          </div>

          <div class="col-lg-12 col-sm-12">
            <div class="form-group">
              <input
                type="text"
                name="phone_number"
                id="phone_number"
                required
                class="form-control"
                [(ngModel)]="formData.phone_number"
                placeholder="Votre téléphone"
              />
            </div>
          </div>

          <div class="col-lg-12 col-md-12">
            <div class="form-group">
              <textarea
                name="message"
                class="form-control"
                id="message"
                cols="30"
                rows="5"
                required
                [(ngModel)]="formData.message"
                placeholder="Votre message"
              ></textarea>
            </div>
          </div>

          <div class="col-lg-12 col-md-12">
            <button
              type="submit"
              class="default-btn page-btn box-btn"
              [disabled]="isSubmitting"
            >
              {{isSubmitting ? 'Envoi en cours...' : 'Envoyer'}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
